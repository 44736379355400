export const Iproducts = [
  {
    id: '2',
    name: 'Cottonwood Apartments',
    title: '',
    imageUrl:
      './Cottonwood Thumbnail.png',
    description:
      '',
      productLink: '/cottonwood-apartments',
      isClosed: false,
  },
  {
    id: '2',
    name: 'The Whitney',
    title: '',
    imageUrl:
      './The Whitney_Thumbnail.png',
    description:
      '',
      productLink: '/the-whitney',
      isClosed: false,
  },
  {
    id: '2',
    name: 'Lofts at Founders Road',
    title: '',
    imageUrl:
      './AVIA_Founders_Square.png',
    description:
      '',
      productLink: '/lofts-at-founders-road',
      isClosed: false,
  },
  {
    id: '1',
    name: 'Monaco Apartments',
    title: '',
    imageUrl:
      './Monaco_Thumbnail.png',
    description:
      '',
      productLink: '/closed-monaco',
      isClosed: true,
  },
  {
    id: '8',
    name: 'Remington Apartments',
    title: '',
    imageUrl:
      './_Remington_Aerial_thumbnail.png',
    description:
      '',
      productLink: '/closed-remington-apartments',
      isClosed: true,
  },
  {
    id: '2',
    name: 'Eagles Landing',
    title: '',
    imageUrl:
      './_Eagles Landing_Square.png',
    description:
      '',
      productLink: '/closed-eagles-landing',
      isClosed: true,
  },
  {
    id: '2',
    name: 'Falls at Canyon Rim',
    title: '',
    imageUrl:
      './Falls_at_Canyon_Rim_Square.png',
    description:
      '',
      productLink: '/closed-falls-at-canyon-rim',
      isClosed: true,
  },
  {
    id: '2',
    name: 'Crossroads Apartments',
    title: '',
    imageUrl:
      './_Crossroads_Square.png',
    description:
      '',
      productLink: '/closed-crossroads-apartments',
      isClosed: true,
  },
  {
    id: '3',
    name: 'Park Station',
    title: '',
    imageUrl:
      './Park_Station_Exterior_Square.png',
    description:
      '',
      productLink: '/closed-park-station',
      isClosed: true,
  },
  {
    id: '4',
    name: 'Canyon Park',
    title: '',
    imageUrl:
      './Canyon_Park_Exterior_Square.png',
    description:
      '',
      productLink: '/closed-canyon-park',
      isClosed: true,
  },
  {
    id: '5',
    name: 'Tuscany Cove',
    title: '',
    imageUrl:
      './Tuscany_Cove_Aerial_Square.png',
    description:
      '',
      productLink: '/closed-tuscany-cove',
      isClosed: true,
  },
  {
    id: '6',
    name: 'Lakeside Village',
    title: '',
    imageUrl:
      './Lakeside_Village_Aerial_Square.png',
    description:
      '',
      productLink: '/closed-lakeside-village',
      isClosed: true,
  },
  {
    id: '7',
    name: 'The Ivy at Draper',
    title: '',
    imageUrl:
      './_The Ivy at Draper_Aerial Square.png',
    description:
      '',
      productLink: '/closed-ivy-at-draper',
      isClosed: true,
  },
  {
    id: '8',
    name: 'North Union',
    title: '',
    imageUrl:
      './North_Union_Aerial_Square.png',
    description:
      '',
      productLink: '/closed-north-union',
      isClosed: true,
  },

  
  // {
  //   id: '1',
  //   name: 'Falls at Canyon Rim',
  //   title: '',
  //   imageUrl:
  //     '/Falls_at_Canyon_Rim_Square.png',
  //   description:
  //     '',
  //     productLink: '/falls-at-canyon-rim',
  //     isClosed: false,
  // },
  
  // {
  //   id: '2',
  //   name: 'North Union',
  //   title: 'DEVELOPMENTS',
  //   imageUrl:
  //     './data/InstitutionalInvestments/Tuscany Cove Cover Photo 1.png',
  //   description:
  //     '233 Units - 2022 - 199,700 Sq Ft',
  //     isClosed: true,
  // },

  // {
  //   id: '3',
  //   name: 'The Ivy at Draper',
  //   title: 'SENIOR HOUSING',
  //   imageUrl:
  //     './data/InstitutionalInvestments/Canyon Park_Cover Photo 2.png',
  //   description:
  //     '277 Units - 2015 - 252,594 Sq Ft',
  //     isClosed: true,
  // },
 
  // {
  //   id: '6',
  //   name: 'Brookfield',
  //   title: '',
  //   imageUrl:
  //     './data/InstitutionalInvestments/Institutional_01.png',
  //   description:
  //     '',
  //     productLink: '/brookfield-apartments',
  //     isClosed: false,
  // },
]