export const images = [
    {
      id: '01',
      src: '/Cottonwood06.jpg',
      alt: 'Whitney',
    },
    {
      id: '02',
      src: '/Cottonwood01.jpg',
      alt: 'Whitney',
    },
    {
      id: '03',
      src: '/Cottonwood02.jpg',
      alt: 'Whitney',
    },
    {
      id: '04',
      src: '/Cottonwood03.jpg',
      alt: 'Whitney',
    },
    {
      id: '05',
      src: '/Cottonwood04.jpg',
      alt: 'Whitney',
    },
    {
        id: '05',
        src: '/Cottonwood05.jpg',
        alt: 'Whitney',
      },
      {
        id: '05',
        src: '/Cottonwood07.jpg',
        alt: 'Whitney',
      },
  ]